import booki from "../assets/images/booki.png";
import ohmyfood from "../assets/images/ohmyfood.png";
import sophiebluel from "../assets/images/sophiebluel.png";
import kasa from "../assets/images/kasa.png";

export const dataprojets = [
    {
        'id': '1',
        'title': 'Projet_booki',
        'name': 'Booki',
        'cover': booki,
        'minDesc': "Intégrer le contenu d'une page d'accueil pour une agence de voyage",
        'desc1': "Pour ce premier projet de formation, le but était de créer une page web statique et responsive en partant d'une maquette Figma et des photos fournis.",
        'desc2': "Ce projet permet de découvrir et appréhender les langages HTML et CSS, mais également de se familiariser avec des outils incontournables, tels que VSCode, Git et GitHub où encore Figma.",
        'langage': ["HTML", "CSS"],
        'github': "https://github.com/Petochh/Projet-Booki-OCR",
        'link': "http://petochh.ddns.net/booki/",
        'type': "formation",
    },
    {
        'id': '2',
        'title': 'Projet_ohmyfood',
        'name': 'Oh My Food',
        'cover': ohmyfood,
        'minDesc': "Développer le frontend d'une application de commande en ligne",
        'desc1': "Oh My Food description longue",
        'desc2': "Oh My Food description longue deux",
        'langage': ["HTML", "SCSS"],
        'github': "https://github.com/Petochh/Projet-ohmyfood-OCR",
        'link': "http://petochh.ddns.net/ohmyfood/",
        'type': "formation",
    },
    {
        'id': '3',
        'title': 'Projet_sophiebluel',
        'name': 'Sophie Bluel',
        'cover': sophiebluel,
        'minDesc': "Créer une page web dynamique grace à JavaScript",
        'desc1': "Sophie Bluel description longue",
        'desc2': "Sophie Bluel description longue deux",
        'langage': ["HTML", "SCSS", "JS"],
        'github': "https://github.com/Petochh/Projet-Sophie-Bluel-OCR",
        'link': "http://petochh.ddns.net/sophiebluel/",
        'type': "formation",
    },
    {
        'id': '4',
        'title': 'Projet_kasa',
        'name': 'Kasa',
        'cover': kasa,
        'minDesc': "Développer le frontend d'une application de location de logements",
        'desc1': "Kasa description longue",
        'desc2': "Kasa description longue deux",
        'langage': ["HTML", "SCSS", "REACT"],
        'github': "https://github.com/Petochh/Projet-Kasa-OCR",
        'link': "http://petochh.ddns.net/kasa/",
        'type': "formation",
    },
]