import { HashLink as Link } from 'react-router-hash-link';
import './navBar.scss';

function Navbar(){
    return (
    <div className="navbar_container">
        <ul className="navbar_container_links">
            <Link to="/#" className="navbar_container_links_link" activeClassName="active" data-content="Home">
                <li>
                    <i className='fa fa-home w3-xxlarge'></i>
                </li>
            </Link>
            <Link to="/#about" className="navbar_container_links_link" activeClassName="active" data-content="A propos">
                <li><i className='fa fa-user w3-xxlarge'></i></li>
            </Link>
            <Link to="/#projet" className="navbar_container_links_link" activeClassName="active" data-content="Projets">
                <li><i className='fa fa-book w3-xxlarge'></i></li>
            </Link>
            <Link to="/#contact" className="navbar_container_links_link" activeClassName="active" data-content="Contact">
                <li><i className='fa fa-envelope w3-xxlarge'></i></li>
            </Link>
        </ul>
    </div>
    
    );
};

export default Navbar;
