
import "./home.scss"

import MySkills from "../../components/skills/skills.jsx"
import ProjetCard from "../../components/projetCard/projetCard"
import FormContact from "../../components/contact/contact.jsx"

import { dataprojets } from "../../data/projects.js"
import { dataformations } from "../../data/formations.js"



function Home(){
    return(
        <div className="home_container">
            <h1>Nicolas Pereira</h1>
            <p>Developpeur web junior</p>
            <h2 className="about_container_title">A propos</h2>
            <div id="about" className="about_container">
                <div className="about_container_presentation">
                    <p>Développeur passionné depuis plus de 10 ans, j'ai décidé de commencer un parcours professionnel en tant que développeur fullstack.</p>
                    <p>J'ai donc commencé en 2023 par la formation d'intégrateur web d'OpenClassrooms.</p>
                    <p>Je développe aussi en Python et compte suivre une formation pour me perfectionner.</p>
                </div>
                <div className="about_container_formation">
                    <span className="about_container_formation_title">Formations</span>
                    {dataformations.map((formation) =>{
                        return(
                            <div className="formation">
                                <p className="formation_time">{formation.time}</p>
                                <p className="formation_name">{formation.name}</p>
                                <p className="formation_skills">{formation.skills}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <MySkills />
            <div id="projet" className="projets_container">
                <h2>Mes projets</h2>
                <div className="projets_container_list">
                    {dataprojets.map((projet) =>{
                        return (
                            <ProjetCard key={projet.id} title={projet.name} cover={projet.cover} langage={projet.langage} description={projet.minDesc} github={projet.github} link={projet.link} />
                        )
                    })}
                </div>
            </div>
            <FormContact />
        </div>
    )
}

export default Home;