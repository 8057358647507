import "./contact.scss"

function FormContact(){
    return(
        <div id="contact" className="form_contact">
            <h2>Me contacter</h2>
            <form className="form_contact_container">
                <input className="form_contact_container_input" type="text" placeholder="Nom" name="name" id="name"/>
                <input className="form_contact_container_input" type="email" placeholder="Email" name="email" id="email"/>
                <textarea className="form_contact_container_input message" type="text" placeholder="Message" rows="6" name="message" id="message"/>
                <button className="form_contact_container_button" disabled><i className="fa fa-paper-plane"></i> Envoyer message</button>
            </form>
        </div>
    )
}

export default FormContact;