
import "./skills.scss"

import iconHtml from "../../assets/icons/icon-html.svg"
import iconCss from "../../assets/icons/icon-css.svg"
import iconSass from "../../assets/icons/icon-sass.svg"
import iconJS from "../../assets/icons/icon-js.svg"
import iconReact from "../../assets/icons/icon-react.svg"
import iconPython from "../../assets/icons/icon-python.svg"
import iconDjango from "../../assets/icons/icons8-django.svg"

function Skills(){
    return(
        <div id="myskill" className="skills_container">
                <h2 className="skills_container_title">Mes competences</h2>
                <ul className="skills_container_list">
                    <li className="skills_container_list_item"><img src={iconHtml} alt="..."/> HTML</li>
                    <li className="skills_container_list_item"><img src={iconCss} alt="..."/> CSS</li>
                    <li className="skills_container_list_item"><img src={iconSass} alt="..."/> SASS</li>
                    <li className="skills_container_list_item"><img src={iconJS} alt="..."/> JavaScript</li>
                    <li className="skills_container_list_item"><img src={iconReact} alt="..."/> React</li>
                    <li className="skills_container_list_item"><img src={iconPython} alt="..."/> Python</li>
                    <li className="skills_container_list_item"><img src={iconDjango} alt="..."/> Django</li>
                </ul>
            </div>
    )
}

export default Skills