import "./footer.scss"

function Footer(){
    return(
        <footer className="footer_container">
            <div>
                Copyright 2024 ©
            </div>
        </footer>
    )
}

export default Footer;