import "./projetCard.scss"


function ProjetCard({title, cover,langage, description, github, link,}){

    return(
        <div className="projetcard">
            <div className="projetcard_container" >
                <img className="projetcard_container_image" src={cover} alt='...' />
                <div className="projetcard_container_text">
                    <span className="projetcard_container_text_title">{title}</span>
                    <span className="projetcard_container_text_description">{langage.map((lang) =>{return(<span className="lang">{lang} </span>)})}</span>
                </div>
                <div className="projet_overlay">
                    <span className="projet_overlay_description">{description}</span>
                    <span className="projet_overlay_github"><a href={github} target="_blank" rel="noreferrer">GitHub</a></span>
                    <span className="projet_overlay_link"><a href={link} target="_blank" rel="noreferrer">Site</a></span>
                </div>
            </div>
        </div>
    )
}

export default ProjetCard;