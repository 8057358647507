import "./error.scss"

function Error(){
    return(
        <div className="error_container">
            <p className="error_container_logo">404</p>
            <p className="error_container_link">Page non trouvée. Retour à l'accueil <a href="/">ici</a></p>
        </div>
    )
}

export default Error;